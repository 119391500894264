<template>
  <div class="ET7">
    <div class="banner">
      <div class="title">ET 7</div>
      <div class="osDMS w">
        <div class="img">
          <img src="@/assets/ET 7页面配图/资源 12ET7产品介绍.png" alt="" />
        </div>
        <div class="img">
          <img src="@/assets/ET 7页面配图/资源 1@4x2.png" alt="" />
        </div>
      </div>
      <div></div>
    </div>
    <!-- <div class="video">
      <video
        src="../../../assets/ET 7页面配图/Compax_Et_7_Video.mp4"
        muted
        autoplay
        loop
      ></video>
    </div> -->
    <ImgVideo :video="video"></ImgVideo>
    <div class="product">
      <div class="top">
        <div class="img">
          <img src="../../../assets/ET 7页面配图/第二页.png" alt="" />
        </div>
      </div>
      <div class="display">
        <div class="title">Display</div>
        <div class="text">15.6” 1366*768</div>
        <div class="text">Capacitive multi-touch screen</div>
      </div>
      <div class="printer">
        <div class="title">Printer</div>
        <div class="text">High speed label printer</div>
      </div>
      <div class="aICamera">
        <div class="title">200M AI Camera</div>
        <div class="text">Support AI recognition</div>
      </div>
      <div class="topline">
        <img src="../../../assets/ET 7页面配图/上.png" alt="" />
      </div>
      <div class="bottom">
        <div class="img">
          <img src="../../../assets/ET 7页面配图/第三页.png" alt="" />
        </div>
      </div>
      <div class="scaleBody">
        <div class="title">Scale body</div>
        <div class="text">Aluminum die-cast</div>
      </div>
      <div class="scalePan">
        <div class="title">Scale pan</div>
        <div class="text">Stainless steel</div>
      </div>
      <div class="bottomline">
        <img src="../../../assets/ET 7页面配图/下.png" alt="" />
      </div>
    </div>
    <div class="ACE2con">
      <div class="w">
        <div class="titleBox">
          <div class="title">ET7</div>
        </div>
        <div class="itemBox">
          <div class="one">
            <div class="itemA">
              <div class="title">OS</div>
              <div class="text">Android 11</div>
            </div>
            <div class="itemA">
              <div class="title">Memory</div>
              <div class="text">RAM 2G</div>
              <div class="text">ROM 16GB</div>
            </div>
            <div class="itemA">
              <div class="title">CPU</div>
              <div class="text">RK3566</div>
              <div class="text">Cortex-A55 Quad-core</div>
              <div class="text">2.0GHz Max</div>
            </div>
            <div class="itemA">
              <div class="title">SCALE</div>
              <div class="text">Measure range：6/15kg</div>
              <div class="text">Division value：2/5g</div>
              <div class="text">Precision：3000e</div>
            </div>
            <div class="itemA">
              <div class="title">WiFi</div>
              <div class="text">2.4GHz</div>
              <div class="text">Support IEEE 802.11 a/b/g/n</div>
            </div>
          </div>
          <div class="two">
            <div class="left">
              <div class="top">
                <div class="itemA">
                  <div class="title">Display</div>
                  <div class="text">Capacitive multi-touch screen</div>
                  <div class="text">15.6" HD screen</div>
                </div>
                <div class="itemB">
                  <div class="title">Printer</div>
                  <div class="text">Direct thermal label printer (60mm)</div>
                  <div class="text">Print speed：150mm/s</div>
                  <div class="text">56mm print width</div>
                  <div class="text">Support 1D & 2D barcodes</div>
                </div>
                <div class="itemA">
                  <div class="title">Camera</div>
                  <div class="text">200M AI Camera</div>
                  <div class="text">Support AI recognition</div>
                </div>
              </div>
              <div class="bottom">
                <div class="left">
                  <div class="itemA">
                    <div class="title">Housing</div>
                    <div class="text">Aluminum die-cast scale body</div>
                    <div class="text">Stainless steel scale pan</div>
                    <div class="text">ABS plastic body</div>
                  </div>
                  <div class="itemA">
                    <div class="title">Ethernet</div>
                    <div class="text">10M/100M</div>
                  </div>
                  <div class="itemA">
                    <div class="title">Bluetooth</div>
                    <div class="text">Bluetooth 4.0</div>
                  </div>
                  <div class="itemA">
                    <div class="title">Power Supply</div>
                    <div class="text">AC IN：</div>
                    <div class="text">AC100-240V; 50-60HZ</div>
                    <div class="text">DC OUT：</div>
                    <div class="text">DC24V/5A</div>
                  </div>
                  <div class="itemA">
                    <div class="title">Speaker</div>
                    <div class="text">4Ω 3W</div>
                  </div>
                  <div class="itemA">
                    <div class="title">Environment</div>
                    <div class="text">Operating Environment：</div>
                    <div class="text">Temperature 0~ 40℃</div>
                    <div class="text">Huminity &lt;85%</div>
                  </div>
                </div>
                <div class="right">
                  <div class="itemC">
                    <div class="title">Interface</div>
                    <div class="text">USB2.0 x 2 (OTG x 1)</div>
                    <div class="text">USB3.0 x 2</div>
                    <div class="text">RJ45 x 1</div>
                    <div class="text">3.5mm jack</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="right">
              <div class="itemD">
                <div class="top">
                  <div class="title">Dimensions</div>
                  <div class="text">380mm*545mm*390mm</div>
                </div>
                <div class="bottomImg">
                  <div class="left text">545mm</div>
                  <div class="right text">390mm</div>
                  <div class="bottom text">380mm</div>
                  <div></div>
                  <div></div>
                  <div class="img">
                    <img src="../../../assets/ET 7页面配图/ET7.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="downloads">
          <div class="box">
            <div class="itemA">
              <div class="title">ET 7</div>
              <div class="text">PDF(2.08MB)</div>
              <div class="icon">
                <a
                  href="https://www.compax.cc/download/manual/ET7.pdf"
                  target="_blank"
                  ><i class="el-icon-bottom"></i
                ></a>
              </div>
            </div>
            <div class="itemB">
              <div class="title">ET 7 User Manual</div>
              <div class="text">PDF(1.47MB)</div>
              <div class="icon">
                <a
                  href="https://www.compax.cc/download/manual/ET7_User_Manual.pdf"
                  target="_blank"
                  ><i class="el-icon-bottom"></i
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImgVideo from '@/components/imgVideo/index'
import video from '../../../assets/ET 7页面配图/Compax_Et_7_Video.mp4'
export default {
  components: {
    ImgVideo
  },
  data() {
    return {
      video: video
    }
  }
}
</script>

<style lang="less" scoped>
.ET7 {
  .banner {
    padding: 43px;
    height: 1006.27px;
    background: url('../../../assets/ET 7页面配图/第一页.png') no-repeat center;
    background-size: cover;
    .title {
      line-height: 104.26px;
      color: rgba(255, 255, 255, 1);
      text-align: center;
      font-size: 72px;
      font-weight: 700;
    }
    .osDMS {
      margin-top: 16px;
      padding: 0px 228px;
      display: flex;
      justify-content: space-between;
      .img {
        width: 363.12px;
        height: 73.4px;
        background-clip: padding-box;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .video {
    height: 1080px;
    video {
      width: 100%;
      height: 100%;
      object-fit: fill;
      object-position: 50% 60%;
    }
  }
  .product {
    position: relative;
    background: rgba(28, 28, 28, 1);
    height: 2160px;
    .top {
      position: absolute;
      top: 243px;
      left: 651px;
      .img {
        width: 618.79px;
        height: 788.5px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .title {
      font-size: 36px;
      color: rgba(0, 92, 255, 1);
      font-weight: 700;
      height: 46px;
      margin-bottom: 5px;
    }
    .text {
      line-height: 35px;
      color: rgba(255, 255, 255, 1);
      font-size: 24px;
    }
    .display {
      position: absolute;
      top: 127px;
      left: 420px;
    }
    .printer {
      position: absolute;
      top: 614px;
      right: 415px;
    }
    .aICamera {
      position: absolute;
      top: 1031px;
      left: 420px;
    }
    .topline {
      position: absolute;
      top: 266px;
      left: 485.5px;
      width: 980.5px;
      height: 746px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .bottom {
      position: absolute;
      top: 1179.93px;
      left: 650px;
      .img {
        width: 618.79px;
        height: 788.5px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .scaleBody {
      position: absolute;
      top: 1930px;
      left: 420px;
    }
    .scalePan {
      position: absolute;
      top: 1543px;
      right: 415px;
      .text {
        text-align: end;
      }
    }
    .bottomline {
      width: 980.5px;
      height: 267.5px;
      position: absolute;
      left: 485.5px;
      top: 1645px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .ACE2con {
    background: rgba(0, 0, 0, 1);
    padding: 118px 11px 0px;
    .titleBox {
      position: relative;
      font-weight: 500;
      line-height: 25px;
      color: rgba(0, 92, 255, 1);
      font-size: 36px;
      padding-bottom: 23px;
      border-bottom: 4px solid rgba(0, 92, 255, 0.5);
      &::after {
        position: absolute;
        left: 0px;
        bottom: -4px;
        content: '';
        width: 95px;
        height: 0px;
        border-bottom: 4px solid rgba(0, 92, 255, 0.5);
        background: rgba(0, 92, 255, 1);
      }
    }
    .itemBox {
      margin-top: 21px;
      padding: 0px 10px;
      .title {
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        color: rgba(255, 255, 255, 1);
        margin-bottom: 5px;
      }
      .text {
        font-size: 12px;
        font-weight: 500;
        line-height: 20px;
        color: rgba(128, 128, 128, 1);
      }
      .itemA {
        margin-top: 23px;
        padding: 19px 0px 0px 26px;
        width: 220px;
        height: 140px;
        opacity: 1;
        border-radius: 20px;
        background: rgba(27, 32, 48, 1);
      }
      .itemB {
        margin-top: 23px;
        padding: 19px 0px 0px 26px;
        width: 460px;
        height: 140px;
        opacity: 1;
        border-radius: 20px;
        background: rgba(27, 32, 48, 1);
      }
      .itemC {
        margin-top: 23px;
        padding: 19px 0px 0px 26px;
        width: 220px;
        height: 303px;
        opacity: 1;
        border-radius: 20px;
        background: rgba(27, 32, 48, 1);
      }
      .itemD {
        position: relative;
        margin-top: 23px;
        padding: 19px 0px 0px 26px;
        width: 220px;
        height: 466px;
        opacity: 1;
        border-radius: 20px;
        background: rgba(0, 92, 255, 0.5);
        .text {
          line-height: 30px;
          color: rgba(255, 255, 255, 1);
        }
        .bottomImg {
          color: rgba(255, 255, 255, 1);
          font-size: 12px;
          font-weight: 500;
          position: absolute;
          top: 182.5px;
          right: 11px;
          width: 177px;
          height: 243.5px;
          .img {
            width: 100%;
            height: 100%;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .top {
            height: 30px;
            position: absolute;
            top: 8px;
            right: 0px;
          }
          .left {
            width: fit-content;
            height: 30px;
            // line-height: 30px;
            position: absolute;
            top: 93.5px;
            left: 7px;
          }
          .right {
            position: absolute;
            top: 111.5px;
            left: 133px;
          }
          .bottom {
            height: 30px;
            position: absolute;
            top: 213.5px;
            left: 56px;
          }
        }
      }
      .one {
        display: flex;
        justify-content: space-between;
      }
      .two {
        display: flex;
        justify-content: space-between;
        .left {
          width: 939px;
          .top {
            display: flex;
            justify-content: space-between;
          }
          .bottom {
            display: flex;
            justify-content: space-between;
            flex-flow: wrap;
            .left {
              width: 699px;
              display: flex;
              flex-flow: wrap;
              justify-content: space-between;
            }
          }
        }
      }
    }
    .downloads {
      margin-top: 150px;
      .box {
        padding: 0px 10px;
        display: flex;
        .title {
          font-size: 20px;
          font-weight: 500;
          line-height: 30px;
          color: rgba(255, 255, 255, 1);
          margin-bottom: 5px;
        }
        .text {
          font-size: 12px;
          font-weight: 500;
          line-height: 20px;
          color: rgba(128, 128, 128, 1);
        }
        .itemA {
          margin-right: 30px;
          padding: 19px 0px 0px 26px;
          width: 220px;
          height: 140px;
          opacity: 1;
          border-radius: 20px;
          background: rgba(27, 32, 48, 1);
          .icon {
            font-size: 24px;
            border-radius: 50%;
            color: rgba(0, 92, 255, 1);
            margin-top: 9px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 36px;
            height: 36.55px;
            border: 1px solid rgba(0, 92, 255, 1);
          }
        }
        .itemB {
          margin-right: 30px;
          padding: 19px 0px 0px 26px;
          width: 251px;
          height: 140px;
          opacity: 1;
          border-radius: 20px;
          background: rgba(27, 32, 48, 1);
          .icon {
            font-size: 24px;
            border-radius: 50%;
            color: rgba(0, 92, 255, 1);
            margin-top: 9px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 36px;
            height: 36.55px;
            border: 1px solid rgba(0, 92, 255, 1);
          }
        }
      }
    }
  }
}
</style>
